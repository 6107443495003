<template>
  <div>
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item>{{ $t("robot.manage") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="contentWrapper robotList">
      <p class="searchRobot">
        <el-input
          :placeholder="$t('robot.factorySerialNumber')"
          v-model="searchvalue"
          clearable
          style="max-width: 482px; margin-right: 12px;min-width: 348px"
        />
        <el-button type="primary" @click="handleSearch" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t('robot.search') }}</el-button>
      </p>
      <div style="min-height: 500px">
        <div class="itemBox">
          <div v-for="item in robotList" :key="item.ID" class="itemPart" :class="{'itemPartEN':enlauges==='en'||enlauges==='es'}">
            <div class="robotItem" @click="getRobotDetail(item)">
              <img :src="totalImg.robotImg" alt class="robotImg"  :style="{opacity: item.status=='leave' ? 0.5 : 1}"/>
              <img :src="item.online && item.status==='binded' ? totalImg.onlineImg : !item.online && item.status==='binded' ? totalImg.offlineImg : totalImg.leaveImg3" alt class="statusImg"/>
              <p class="robotName">{{ item.nickname ? item.nickname : setRobotName(item.software_num) }}</p>
              <div class="batteryImg">
                <div v-show="item.status != 'leave'">
                  <span></span>
                  <span :style="{background:item.battery_info?JSON.parse(item.battery_info).level<10?'red':'#5BD171': item.battery < 10 ? 'red':'#5BD171',width: item.battery_info?JSON.parse(item.battery_info).level * 125/100 + 'px':item.battery * 125/100 + 'px', opacity: item.status=='leave' ? 0.5 : 1}"></span>
                  <!-- <span>{{ item.battery ? item.battery+'%' : null }}</span>     -->
                  <span>{{item.battery_info?JSON.parse(item.battery_info).level + '%':item.battery ? item.battery+'%' : null}}</span>
                </div>
              </div>
              <div class="robotInfo">
                <div style="margin-right: 28px;">
                  <p>
                    <span class="text1">{{item.stat_count>9999?'9999+':item.stat_count}}</span>
                    <span class="text2">  {{$t('robot.second')}}</span>
                  </p>
                  <p class="text">{{ $t('dataReport.cumulativeTaskTimes') }}</p>
                </div>
                <div>
                  <p class="num2">
                    <span class="text1">{{getTime(item.stat_time/1, 1) != 0 ? getTime(item.stat_time/1, 1)>9999?'9999+':getTime(item.stat_time/1, 1) : ''}}</span>
                    <span class="text2">  {{getTime(item.stat_time/1, 1) != 0 ? 'h' : ''}}</span>
                    <span class="text1" v-show="getTime(item.stat_time/1, 1)<100">{{getTime(item.stat_time/1, 2)}}</span>
                    <span class="text2" v-show="getTime(item.stat_time/1, 1)<100">  min</span>
                  </p>
                  <p class="text">{{ $t('dataReport.disinfectDuration') }}</p>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        v-if="total/params.pageSize > 1"
        :page-size="params.pageSize"
        :total="total"
        :page-sizes="[6, 12, 24, 48, 96, 200]"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, sizes, jumper"
        class="pagination"
      />
      <div v-else class="noPage">{{$t('system.page').replace('XX', total)}}</div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs,
  inject
} from "vue";
import { getRobotList } from "@/apis/user";
import { useRouter } from "vue-router";
import moment from "moment";
import { getHM, totalImg, setRobotName } from "@/utils/robot";
import { ElMessageBox, ElMessage  } from 'element-plus';
import {getLanguage} from "@/utils/cookies"
import i18n from "@/locales";
const _t = i18n.global.t;

export default {
  components: {},
  setup() {
    const router = useRouter()
    const state = reactive({
      params: {
        page: 1,
        pageSize: 6,
        limit: true,
        search: ""
      },
      abc:'',
      searchvalue:'',
      totalImg: totalImg(),
      getHM: getHM,
      setRobotName: setRobotName,
      total: 0,
      robotList: [],
      enlauges:false,
      getList(flge=false) {
        if (flge) {
          state.params.search = state.searchvalue
        }
        state.params.search = state.params.search.replace(/\s*/g, "");
        getRobotList(state.params, true).then(res => {
          if (res.code == 200) {
            state.robotList = res.data.lists;
            state.total = res.data.total;
          }
          // console.log("state.robotList=>", state.robotList);
        });
      },
      //  async getList(params) {
      //   const data = await getRobotList(params)
      //   state.robotList = data?.data.items ?? []
      //   state.total = data?.data.total ?? 0
      // },
      getTime(val, type) {
        if(val == 0) return val
        //  val = val * 1000
        // console.log(val,(Number(val)/60)%60)
        // const tempTime = moment.duration(val);
        let hours = parseInt(Number(val)/3600),
            minutes = parseInt((Number(val)/60)%60)
            // seconds = tempTime.seconds() ? tempTime.seconds() : 0
        return type == 1 ? hours : minutes
      },
      handleCurrentChange(page) {
        state.params.page = page;
        state.getList();
      },
      handleSizeChange(val) {
        state.params.pageSize = val;
        state.getList();
      },
      handleSearch(flge=true) {
        state.params.page = 1;
        state.getList(flge);
      },
      getRobotDetail(item) {
        sessionStorage.sn = item.sn;
        router.push(`/robot/robotInfo/${item.sn}`);
      },
    });
    onMounted(() => {
      state.getList();
      state.enlauges = getLanguage('languageKey')
      console.log(state.enlauges)
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
.robotList {
  position: relative;
  .itemBox {
    margin-top: 24px;
    .itemPart {
      display: inline-block;
      width: 385px;
      vertical-align: top;
      white-space: nowrap;
    }
    .itemPartEN{
      width: 430px;
    }
  }
  .robotItem {
    display: inline-block;
    position: relative;
    vertical-align: top;
    min-height: 198px;
    width: 90%;
    padding: 38px 20px 20px 110px !important;
    margin: 0 20px 30px;
    box-shadow: 2px 4px 5px 0px rgba(0, 21, 41, 0.12),
      -2px 0px 5px 0px rgba(0, 21, 41, 0.12);
    border-radius: 4px;
    font-size: 14px !important;
    background: #ffffff;
    cursor: pointer;
    &:hover {
      background: #e9e9e9;
      transition: all 0.5s ease-in-out;
    }

    .robotImg {
      position: absolute;
      height: 136px;
      top: 30px;
      left: 30px;
    }
    .statusImg {
      position: absolute;
      top: 28px;
      left: 76px;
    }
    .delButton{
      color: #fafafa;
      font-size: 16px;
      position: absolute;
      top: 8px;
      right: 14px;
    }
    .robotName {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin: 0 0 8px;
      word-break: break-all;
      white-space: normal;
    }
    .batteryImg {
      position: relative;
      white-space: nowrap;
      height: 20px;
      line-height: 20px;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      span:nth-child(1) {
        width: 124px;
        height: 10px;
        background: #D8D8D8;
        border-radius: 2px;
        margin-right: 10px;
      }
      span:nth-child(2) {
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 10px;
        border-radius: 2px;
      }
    }
    .robotInfo {
      // white-space: nowrap;
      padding-top: 28px;
      div {
        display: inline-block;
        vertical-align: middle;
        .text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
        }
      } 
      p {
        margin: 0 0 6px;
      }
      .text1 {
        font-size: 24px;
        font-family: HelveticaNeue-Medium, HelveticaNeue;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
      }
      .text2 {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    // p {
    //   img {
    //     width: 24px;
    //     margin: -4px 4px 0 0;
    //   }
    //   span {
    //     display: inline-block;
    //     vertical-align: top;
    //   }
    // }
  }
  .robotTitle {
    font-size: 18px;
    font-weight: bold;
  }
  .searchRobot {
    margin: 0 20px 24px;
  }
}
	.noPage{
		text-align: right;
    padding: 46px 24px 28px;
    font-size: 14px;
    color: #606266;
	}
</style>
